import { createRouter, createWebHashHistory } from 'vue-router'
import Player from '../views/Player/Player.vue'


const routes = [
  {
    path: '/',
    name: 'Player',
    component: Player
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
