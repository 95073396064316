<template>
  <div id="player-container">
    <!-- 固定部分容器 -->
    <div id="fixed-elements">
      <!-- 图片广告 -->
      <div v-if="adData" id="ad-banner">
        <a :href="adData.linkUrl" target="_blank">
          <img :src="adData.imageUrl" alt="广告图片" />
        </a>
      </div>

      <!-- 显示剩余次数的标签 -->
      <div id="remaining-plays">
        {{ frequencyLabelText }} <br />
        当前可刷新次数： {{ playCount }} 次
      </div>

      <video ref="videoPlayer" class="video" controls playsinline webkit-playsinline>
        <source :src="videoUrl.mp4" type="video/mp4" />
        <source :src="videoUrl.m3u8" type="application/x-mpegURL" />
        Your browser does not support the video tag.
      </video>

      <div id="announcement">
        <i class="fas fa-bullhorn"></i>
        <span>{{ announcementText }}</span>
      </div>

      <button class="play" @click="playRandomVideo">
        {{ buttonText }}
      </button>

      <!-- 新增分享按钮 -->
      <button class="share" @click="handleShareClick">
        分享给朋友
      </button>
    </div>

    <!-- 动态按钮容器 -->
    <div v-if="dynamicButtons.length > 0" id="dynamic-buttons">
      <button v-for="button in dynamicButtons" :key="button.link" @click="redirectTo(button.link)"
        :style="{ backgroundColor: button.color, width: buttonWidth }">
        {{ button.title }}
      </button>
    </div>

    <!-- 底部标签容器 -->
    <div id="bottom-labels">
      <!-- 新增的标签 -->
      <div id="share-instructions">
        <p v-for="(label, index) in bottomLabels" :key="index">{{ label }}</p>
      </div>
    </div>

    <!-- 对话框组件 -->
    <div v-if="showDialog" id="dialog">
      <p>{{ dialogData.title }}</p>
      <p v-for="(line, index) in dialogData.content" :key="index">{{ line }}</p>
      <button class="copy-link" @click="copyLink">{{ dialogData.buttonText }}</button>
      <button class="close-dialog" @click="closeDialog">{{ dialogData.closeButtonText }}</button>
    </div>

    <!-- 定时提示对话框 -->
    <div v-if="promptDialogVisible" id="prompt-dialog">
      <p>{{ promptDialogData.title }}</p>
      <p v-for="(line, index) in promptDialogData.content" :key="index">{{ line }}</p>
      <button class="close-dialog" @click="closePromptDialog">关闭</button>
    </div>

    <!-- 新的复制成功提示对话框 -->
    <div v-if="showCopySuccessDialog" id="copy-success-dialog" class="custom-dialog">
      <p>复制成功</p>
      <p>专属链接已成功复制到剪贴板</p>
      <button @click="closeCopySuccessDialog">确定</button>
    </div>


  </div>
</template>

<script>
import apiClient from '@/api/api';

export default {
  data() {
    return {
      adData: null, // 存储广告数据
      frequencyLabelText: '万部电影免费看，分享一人看10部', // 存储频率标签的文本
      bottomLabels: [], // 存储底部标签文本
      videoUrl: {
        mp4: '',
        m3u8: ''
      },
      playCount: 0, // 剩余播放次数
      videoLinks: [], // 保存视频链接的数组
      playedLinks: [], // 保存已播放的视频链接
      buttonDisabled: false, // 按钮禁用状态
      buttonText: '换一部电影', // 按钮标题
      countdown: 5, // 倒计时时间
      announcementText: '', // 公告文本
      announcements: [], // 动态加载的公告消息列表
      currentAnnouncementIndex: 0, // 当前公告的索引
      showDialog: false, // 控制普通对话框显示
      showCopySuccessDialog: false, // 控制新的复制成功对话框显示与隐藏
      shareLink: '', // 存储专属链接
      dynamicButtons: [], // 动态按钮数据
      buttonWidth: '120px', // 按钮宽度
      dialogData: {
        title: '', // 对话框标题（原有对话框）
        content: [], // 对话框内容（原有对话框）
        buttonText: '', // 按钮文本（原有对话框）
        closeButtonText: '' // 关闭按钮文本（原有对话框）
      },
      // 新增：定时提示对话框数据
      promptDialogData: {
        title: '', // 定时提示对话框的标题
        content: [], // 定时提示对话框的内容
        delaySeconds: 0, // 初次弹出的延时
        repeatIntervalSeconds: 0, // 再次弹出的间隔时间
        repeatTimes: 0 // 总共弹出的次数
      },
      promptDialogVisible: false, // 控制定时提示对话框的显示
      promptDialogCount: 0 // 当前已经弹出的次数

    };
  },
  methods: {
    checkDevice() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile = /iphone|ipod|android|mobile/.test(userAgent);

      if (!isMobile) {
        // 如果是电脑设备，则显示禁止访问的消息或重定向
        // alert("此页面仅限移动设备访问。您将被重定向。");
        // window.location.href = "https://www.baidu.com/";  // 重定向到指定页面
      }

    },
    async loadVideoLinks() {
      try {
        const response = await fetch('data/d6523b221d4f231b.txt?timestamp=${new Date().getTime()}'); // 从 public 文件夹加载
        const text = await response.text();
        this.videoLinks = text.split('\n').filter(link => link.trim() !== ''); // 分割并去除空行
      } catch (error) {
        console.error('无法加载视频链接文件:', error);
      }
    },
    selectRandomVideo() {
      if (this.videoLinks.length === 0) {
        this.videoLinks = [...this.playedLinks];
        this.playedLinks = [];
      }

      if (this.videoLinks.length === 0) {
        console.log('没有可用的视频链接');
        return;
      }

      const randomIndex = Math.floor(Math.random() * this.videoLinks.length);
      const selectedLink = this.videoLinks.splice(randomIndex, 1)[0];
      this.playedLinks.push(selectedLink);

      // 根据链接后缀确定格式
      if (selectedLink.endsWith('.m3u8')) {
        this.videoUrl = { mp4: '', m3u8: selectedLink };
      } else {
        this.videoUrl = { mp4: selectedLink, m3u8: '' };
      }

      console.log('选择的视频链接:', this.videoUrl);
    },
    async playRandomVideo() {
      if (this.buttonDisabled) return; // 如果按钮已禁用，则不执行操作

      try {
        // 检查用户是否有足够的播放次数
        const checkResponse = await apiClient.post('video.php', { action: 'checkPlayCount' });
        if (checkResponse.data.success && checkResponse.data.play_count > 0) {
          this.playCount = checkResponse.data.play_count; // 更新播放次数显示

          // 如果视频链接未加载过，重新加载
          if (this.videoLinks.length === 0) {
            await this.loadVideoLinks();
          }

          // 减少用户播放次数
          const decrementResponse = await apiClient.post('video.php', { action: 'decrementPlayCount' });
          if (decrementResponse.data.success) {
            this.playCount = decrementResponse.data.play_count; // 成功减少播放次数后，获取最新的播放次数

            // 禁用按钮并启动倒计时
            this.buttonDisabled = true;
            this.buttonText = `${this.countdown} 秒后可重试`; // 更新按钮标题

            const intervalId = setInterval(() => {
              this.countdown -= 1;
              this.buttonText = `${this.countdown} 秒后可重试`;
              if (this.countdown <= 0) {
                clearInterval(intervalId);
                this.buttonDisabled = false;
                this.buttonText = '换一部电影';
                this.countdown = 5; // 重置倒计时时间
              }
            }, 1000);

            this.selectRandomVideo(); // 选择随机视频

            const videoPlayer = this.$refs.videoPlayer;
            if (videoPlayer) {
              videoPlayer.load(); // 重新加载视频
              await videoPlayer.play();
            }
          } else {
            // 减少播放次数失败，可能是播放次数不足
            this.showDialog = true;
            await this.generateShareLink(); // 生成专属链接
          }
        } else {
          // 播放次数不足，显示对话框
          this.showDialog = true;
          await this.generateShareLink(); // 生成专属链接
        }
      } catch (error) {
        console.error('播放视频失败:', error);
      }
    },

    async generateShareLink() {
      try {
        // 调用后台生成专属链接
        const response = await apiClient.post('video.php', { action: 'generateShareLink' });
        if (response.data.success) {
          this.shareLink = response.data.share_link;
        } else {
          console.error('生成专属链接失败:', response.data.message || '未知错误');
        }
      } catch (error) {
        console.error('生成专属链接失败:', error);
      }
    },

    async handleShareClick() {
      if (!this.shareLink) {
        await this.generateShareLink();
      }

      if (navigator && navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
        navigator.clipboard.writeText(this.shareLink)
          .then(() => this.showNewCopySuccessDialog()) // 显示自定义的复制成功对话框
          .catch(err => console.error('复制链接失败:', err));
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = this.shareLink;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          const successful = document.execCommand('copy');
          if (successful) {
            this.showNewCopySuccessDialog(); // 显示自定义的复制成功对话框
          } else {
            alert('无法复制链接，请手动复制。');
          }
        } catch (err) {
          console.error('复制链接失败:', err);
          alert('无法复制链接，请手动复制。');
        }
        document.body.removeChild(textArea);
      }
    },

    copyLink() {
      if (navigator && navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
        navigator.clipboard.writeText(this.shareLink)
          .then(() => this.showNewCopySuccessDialog()) // 显示自定义的复制成功对话框
          .catch(err => console.error('复制链接失败:', err));
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = this.shareLink;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          const successful = document.execCommand('copy');
          if (successful) {
            this.showNewCopySuccessDialog(); // 显示自定义的复制成功对话框
          } else {
            alert('无法复制链接，请手动复制。');
          }
        } catch (err) {
          console.error('复制链接失败:', err);
          alert('无法复制链接，请手动复制。');
        }
        document.body.removeChild(textArea);
      }
    },

    // 新的对话框方法，用于显示复制成功提示
    showNewCopySuccessDialog() {
      this.showCopySuccessDialog = true;  // 显示新的复制成功对话框
    },

    // 关闭新的复制成功对话框
    closeCopySuccessDialog() {
      this.showCopySuccessDialog = false; // 关闭新的复制成功对话框
    },

    closeDialog() {
      this.showDialog = false;
    },
    async recordUser() {
      try {
        await apiClient.post('video.php', { action: 'recordUser' });
      } catch (error) {
        console.error('记录用户失败:', error);
      }
    },
    async getPlayCount() {
      try {
        const response = await apiClient.post('video.php', { action: 'checkPlayCount' });
        if (response.data.success) {
          this.playCount = response.data.play_count;

          // 如果获取到的播放次数大于 0，且视频链接没有加载过，则加载视频链接
          if (this.playCount > 0 && this.videoLinks.length === 0) {
            await this.loadVideoLinks();
            console.log('成功加载视频链接');
          }
        } else {
          console.error('无法获取播放次数:', response.data.message);
        }
      } catch (error) {
        console.error('获取播放次数失败:', error);
      }
    },
    updateAnnouncement() {
      this.announcementText = this.announcements[this.currentAnnouncementIndex];
      this.currentAnnouncementIndex = (this.currentAnnouncementIndex + 1) % this.announcements.length;
    },
    // 新增：动态加载动态按钮内容
    async loadDynamicButtons() {
      try {
        const response = await fetch('/data/dynamicButtons.json?timestamp=${new Date().getTime()}');
        const data = await response.json();
        this.dynamicButtons = data.buttons.map(button => {
          return {
            ...button,
            color: this.getRandomColor()
          };
        });
      } catch (error) {
        console.error('无法加载动态按钮数据:', error);
      }
    },

    redirectTo(link) {
      window.open(link, '_blank'); // 在新窗口或新标签页中打开链接
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      // 将颜色转换为 HSL 格式，并调整亮度
      const hslColor = this.hexToHsl(color);
      hslColor.l = Math.max(0.4, Math.min(0.8, hslColor.l)); // 限制亮度在 40% 到 80% 之间
      return this.hslToHex(hslColor.h, hslColor.s, hslColor.l);
    },
    hexToHsl(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      let r = parseInt(result[1], 16) / 255;
      let g = parseInt(result[2], 16) / 255;
      let b = parseInt(result[3], 16) / 255;
      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      let h, s, l = (max + min) / 2;

      if (max === min) {
        h = s = 0; // achromatic
      } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r: h = (g - b) / d + (g < b ? 6 : 0); break;
          case g: h = (b - r) / d + 2; break;
          case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
      }

      return { h, s, l };
    },
    hslToHex(h, s, l) {
      let r, g, b;

      if (s === 0) {
        r = g = b = l; // achromatic
      } else {
        const hue2rgb = (p, q, t) => {
          if (t < 0) t += 1;
          if (t > 1) t -= 1;
          if (t < 1 / 6) return p + (q - p) * 6 * t;
          if (t < 1 / 2) return q;
          if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
          return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
      }

      const toHex = x => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      };

      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    },

    async loadAdData() {
      try {
        const response = await fetch('/data/picture.json?timestamp=${new Date().getTime()}');
        const data = await response.json();
        this.adData = data;
      } catch (error) {
        console.error('无法加载广告数据:', error);
      }
    },

    async loadFrequencyLabel() {
      try {
        const response = await fetch('/data/Frequencylabel.json?timestamp=${new Date().getTime()}');
        const data = await response.json();
        this.frequencyLabelText = data.labelText || this.frequencyLabelText;
      } catch (error) {
        console.error('无法加载频率标签数据:', error);
      }
    },

    async loadNotices() {
      try {
        const response = await fetch('/data/Notice.json?timestamp=${new Date().getTime()}');
        const data = await response.json();
        this.announcements = data.announcements || this.announcements;
        this.updateAnnouncement(); // 确保初始化时显示第一个公告
      } catch (error) {
        console.error('无法加载公告数据:', error);
      }
    },

    async loadBottomLabels() {
      try {
        const response = await fetch('/data/bottom.json?timestamp=${new Date().getTime()}');
        const data = await response.json();
        this.bottomLabels = data.labels || [];
      } catch (error) {
        console.error('无法加载底部标签数据:', error);
      }
    },

    // 新增：动态加载原本对话框内容
    async loadDialogContent() {
      try {
        const response = await fetch('/data/Dialog.json?timestamp=${new Date().getTime()}');
        const data = await response.json();
        this.dialogData = data; // 加载原有对话框的内容
      } catch (error) {
        console.error('无法加载对话框内容:', error);
      }
    },

    // 新增：加载定时提示对话框的数据
    async loadPromptData() {
      try {
        const response = await fetch('/data/prompt.json?timestamp=${new Date().getTime()}');
        const data = await response.json();
        this.promptDialogData = data;
        this.startPromptDialog(); // 开始定时提示
      } catch (error) {
        console.error('无法加载定时提示对话框内容:', error);
      }
    },
    // 新增：开始定时提示对话框
    startPromptDialog() {
      // 第一次延时弹出
      setTimeout(() => {
        this.showPromptDialog();
        // 设置每次的重复弹出
        const intervalId = setInterval(() => {
          if (this.promptDialogCount >= this.promptDialogData.repeatTimes) {
            clearInterval(intervalId); // 达到指定次数后停止弹出
          } else {
            this.showPromptDialog();
          }
        }, this.promptDialogData.repeatIntervalSeconds * 1000);
      }, this.promptDialogData.delaySeconds * 1000);
    },
    // 新增：显示定时提示对话框
    showPromptDialog() {
      this.promptDialogCount += 1; // 记录弹出次数
      this.promptDialogVisible = true;
    },
    // 新增：关闭定时提示对话框
    closePromptDialog() {
      this.promptDialogVisible = false;
    },

    // 检查是否为专属链接访问
    async checkReferral(ref) {
      try {
        const response = await apiClient.post('video.php', { action: 'checkReferral', ref });
        if (response.data.success) {
          console.log('播放次数已增加');
          this.getPlayCount(); // 更新播放次数
        } else {
          console.log('该IP已访问过此专属链接或其他错误:', response.data.message);
        }
      } catch (error) {
        console.error('检查专属链接失败:', error);
      }
    },
  },
  mounted() {
    this.checkDevice();

    // 检查播放次数
    this.getPlayCount().then(() => {
      if (this.playCount > 0) {
        this.loadVideoLinks(); // 只有在播放次数大于 0 时才加载视频链接
        this.playRandomVideo(); // 只有在有播放次数时才随机播放视频
      } else {
        this.showDialog = true; // 播放次数不足时弹出对话框
      }
    });

    this.checkDevice();  // 页面加载时自动调用检查设备
    this.generateShareLink(); // 生成专属链接
    this.loadAdData(); // 加载广告数据
    this.loadFrequencyLabel(); // 加载频率标签数据
    this.loadNotices(); // 加载公告数据
    this.loadBottomLabels(); // 加载底部标签数据
    this.recordUser(); // 记录用户访问
    this.loadDynamicButtons(); // 动态加载按钮

    setInterval(this.updateAnnouncement, 3000); // 每 3 秒更新一次公告

    setInterval(() => {
      this.loadAdData();
      this.loadFrequencyLabel();
      this.loadNotices();
      this.loadBottomLabels();
      this.loadDialogContent(); // 动态加载原有对话框内容
      this.loadDynamicButtons(); // 动态加载按钮
    }, 10000); // 每 10 秒重新加载一次数据

    const symbols = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '_', '+', '=', '?', '~'];
    const randomSymbol1 = symbols[Math.floor(Math.random() * symbols.length)];
    const randomSymbol2 = symbols[Math.floor(Math.random() * symbols.length)];
    const randomTitle = randomSymbol1 + Math.random().toString(36).substring(2, 18) + randomSymbol2;
    document.title = randomTitle;

    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get('ref');
    if (ref) {
      this.checkReferral(ref);
    }

    this.loadDialogContent(); // 页面加载时获取原有对话框内容
    this.loadPromptData(); // 页面加载时获取定时提示对话框内容
  }
};
</script>

<style scoped>
html,
body {
  background-color: #24292e;
  /* 设置页面整体背景颜色 */
  margin: 0;
  padding: 0;
  height: 100%;
}

#player-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #24292e;
  /* 保持容器背景色与页面背景色一致 */
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  min-height: 100vh;
  /* 确保容器至少填充整个视口 */
}

/* 固定部分容器 */
#fixed-elements {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* 广告图片容器样式 */
#ad-banner {
  width: 95%;  /* 容器宽度为页面的 100% */
  max-width: 400px;  /* 设置最大宽度 */
  margin-top: 20px;
  height: auto; /* 根据内容自动调整高度 */
  display: flex;
  justify-content: center; /* 让图片居中显示 */
}

/* 广告图片样式 */
#ad-banner img {
  width: 100%;  /* 图片占满容器 */
  height: auto;  /* 图片高度自动调整，保持比例 */
  max-height: 200px; /* 设置图片的最大高度，你可以根据需要调整 */
  object-fit: contain;  /* 确保图片不被裁剪，并保持宽高比 */
  cursor: pointer;
}


/* 调整剩余次数标签与顶部的间距 */
#remaining-plays {
  margin-top: 20px;
  color: white;
  font-size: 16px;
  text-align: center;
}

.video {
  width: 100%;
  max-height: 35vh;
  object-fit: contain;
  background-color: black;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

#announcement {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 10px;
  color: white;
  font-size: 16px;
  text-align: left;
  width: 100%;
  padding-left: 20px;
}

#announcement i {
  font-size: 20px;
  margin-right: 10px;
  vertical-align: middle;
}

#announcement span {
  line-height: 1.5;
}

button {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: rgb(248, 248, 248);
  cursor: pointer;
  min-width: 90%;
  text-align: center;
  white-space: nowrap;
}

button.play {
  background-color: #2885f0;
}

button.play:active {
  background-color: #6495ed;
}

button.share {
  background-color: #ad4d4c;
  margin-bottom: -10px;
  /* 调整分享按钮与动态按钮的距离 */
}

button.share:active {
  background-color: #ff6347;
}

button:disabled {
  background-color: #5386b3;
  cursor: not-allowed;
}

/* 动态按钮容器 */
#dynamic-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

#dynamic-buttons button {
  margin: 5px;
}

#dynamic-buttons button:active {
  opacity: 0.8;
}

/* 底部标签容器 */
#bottom-labels {
  margin-top: 20px;
  width: 100%;
  color: white;
  text-align: center;
}

#share-instructions {
  font-size: 12px;
}

#share-instructions p {
  margin: 5px 0;
}

/* 修复对话框样式，确保内容显示 */
#dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(255, 255, 255);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 80vw;
  max-width: 400px;
  z-index: 1000;
  /* 确保对话框位于最前 */
  color: black;
  /* 设置文本颜色为黑色，确保可见 */
}

#dialog p {
  margin-bottom: 15px;
  font-size: 14px;
  color: black;
  /* 确保文本颜色为黑色 */
}

#dialog .copy-link {
  background-color: rgb(54, 129, 241);
  color: white;
  margin: 10px 0;
  padding: 10px 20px;
  width: 100%;
}

#dialog .copy-link:active {
  background-color: rgb(77, 140, 235);
}

#dialog .close-dialog {
  background-color: #808080;
  color: white;
  margin-top: 10px;
  padding: 10px 20px;
  width: 100%;
}

#dialog .close-dialog:active {
  background-color: #696969;
}

/* 弹出对话框的样式 */
#prompt-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(255, 255, 255);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 80vw;
  max-width: 400px;
  z-index: 1000;
  color: black;
}

#prompt-dialog p {
  margin-bottom: 15px;
  font-size: 14px;
}

#prompt-dialog .close-dialog {
  background-color: #808080;
  color: white;
  margin-top: 10px;
  padding: 10px 20px;
  width: 100%;
}

#prompt-dialog .close-dialog:active {
  background-color: #696969;
}


/* 调整所有按钮的字体大小和高度 */
button {
  font-size: 15px;
  /* 调整按钮的字体大小 */
  padding: 9px 25px;
  /* 增加按钮的高度 */
}

/* 自定义对话框的样式 */
.custom-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 1000;
}

/* 对话框内的按钮样式 */
.custom-dialog button {
  background-color: #007bff;
  /* 蓝色背景 */
  color: white;
  /* 白色文字 */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>